.portfolio_section {

  @media(min-width: 768px) {
    display: flex;
    justify-content: center;
  }

  .sphere_sm {
    position: absolute;
    top: -100px;
    left: -50%;
    right: 0;
    margin: auto;
  }

  .sphere_md {
    position: absolute;
    bottom: -200px;
    left: 0;
  }

  .sphere_lg {
    position: absolute;
    top: 0;
    right: 10%;
    opacity: .7;
    z-index: -1;
    @media(min-width: 768px) {
      opacity: 1;
    }
  }

  .portfolio_cont {
    max-width: 550px;
    flex: 1 1 auto;

    h1 {
      margin-top: 10px;
      margin-bottom: 0;
      max-width: 300px;

      @media(min-width: 475px) {
        max-width: 380px;
      }

      @media(min-width: 768px) {
        text-align: center;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
      }

      span {
        display: inline-block;
        transition: all .3s ease-out;

        &.space {
          padding: 0 15px;
        }

        &:hover {
          color: #F4A949;
          animation: rubberBand;
          animation-duration: 1s;
        }
      }
    }

    .portfolio_blurb {
      padding: 1.5rem 0;
      margin: 0;

      @media(min-width: 768px) {
        text-align: center;
      }
    }

    .proj_link {
      
      @media(min-width: 768px) {
        display: flex;
        justify-content: center;
      }

      a {
        display: flex;
        text-decoration: none;
        font-weight: 600;
        display: flex;
        align-items: center;
        color: #9C27B0;
      }
    }

    a {
      text-decoration: none;
      font-weight: 600;
      color: #F4A949;

      &:hover {
        opacity: .9;
      }

      svg {
        margin-left: 5px;
        transition: transform .3s ease-out;
        transform: rotate(-90deg);
      }

      &:hover {
        svg {
          transform: rotate(-90deg) translateY(10px);
        }
      }
    }
  }

  .portfolio_particles {
    flex: 1 1 auto;

    #tsparticles {
      height: 450px;
    }

  }
}