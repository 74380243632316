.computer_graphic {
  svg {
    width: 100%;
    height: 100%;
    max-width: 900px;

    .code_graph {
      path {
        animation: float 4s ease-in-out infinite;
        transition: all .3s ease-out;
        
      }
      &:hover {
        path:not(#path3776):not(#path3780):not(#path3772) {
          fill: #F4A949!important;
        }
      }
    }
  }
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-300px);
	}
	100% {
		transform: translatey(0px);
	}
}