@import "~animate.css/animate.css";
:root {
  --animate-delay: 0.2s;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: 24px;
  color: aliceblue;
  background: #141416;

  h1 {
    font-size: 36px;
    line-height: 42px;
  }

  .font-60 {
    font-size: 30px;
    cursor: default;

    @media(min-width: 475px) {
      font-size: 40px;
    line-height: 34px;
    }

    @media(min-width: 768px) {
      font-size: 60px;
      line-height: 54px;
    }
  }

  .text_white {
    color: aliceblue;
  }

  .text_blue {
    color: #1C5F9A;
  }

  .btn_light {
    background: aliceblue;
    padding: 8px 22px;
    display: block;
    border: 2px solid aliceblue;
    background-color: transparent;
    border-radius: 6px;
    font-weight: 600;
    text-decoration: none;
    font-size: 17px;
    color: aliceblue;
    cursor: pointer;
    transition: all .3s ease-in-out;
    &:hover {
      background-color: aliceblue;
      color: black;
    }

    @media(min-width: 768px) {
      padding: 8px 32px;
    }
  }

  .logo {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    top: 5px;
    
    svg {
      max-width: 40px;
      position: relative;
      top: 1px;
      margin-right: 2px;
      .blinkit {
        animation: blink-anime 1.6s infinite;
      }
    }
  
    h1 {
      font-size: 32px;
      line-height: 1;
      margin: 0;
      color: aliceblue;
      display: inline;
      font-weight: 700;
      letter-spacing: 5px;
      font-family: 'Source Sans Pro', sans-serif;
    }
  }

  
  // Blinking animation
  @keyframes blink-anime {
    0%{     fill: #000000;    }
    49%{    fill: #000000; }
    60%{    fill: transparent; }
    99%{    fill:transparent;  }
    100%{   fill: #000000;    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
