.skills_section {
  padding: 0 0 100px;
  @media(min-width: 768px) {
    text-align: center;
  }

  @media(min-width: 992px) {
    padding: 0;
  }

  p {
    max-width: 700px;
    @media(min-width: 768px) {
      margin: 0 auto 1rem;
    }

    a {
      color: #F4A949;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  h1 {
    margin-top: 10px;
    margin-bottom: 1.5rem;
    font-size: 32px;

    @media(min-width: 380px) {
      font-size: 39px;
    }

    @media(min-width: 1140px) {
      font-size: 60px;
    }
      

    span {
      display: inline-block;
      transition: all .3s ease-out;

      &.space {
        padding-right: 15px;
        padding-left: 15px;
      }

      &:hover {
        color: #F4A949;
        animation: rubberBand;
        animation-duration: 1s;
      }
    }
  }

  h2 {
    text-transform: uppercase;
    font-weight: 300;
    opacity: .7;
    margin-bottom: 0;
    font-size: 15px;
    padding-right: 1rem;
    @media(min-width: 768px) {
      font-size: 18px;
      padding-right: 1rem;
    }
  }

  .skills_brands {
    margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;

    @media(min-width: 768px) {
      max-width: 1000px;
      justify-content: center;
    }

    div {
      text-align: center;
      margin: 0 0 30px 0;
      font-size: 16px;
      width: 33%;

      @media(min-width: 430px) {
        font-size: inherit;
      }

      @media(min-width: 768px) {
        width: auto;
      }

      p {
        margin: 0;
        @media(min-width: 430px) {
          margin: 0 0 0 10px;
        }
        @media(min-width: 768px) {
          margin: 0;
        }
      }

      svg {
        width: 50px;
        height: 40px;
      }

      @media(min-width: 768px) {
        display: block;
        margin: 0 30px 3rem;
        font-size: inherit;
        svg {
          width: 50px;
          height: 50px;
        }
      }

      @media(min-width: 1010px) {
        svg {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
}